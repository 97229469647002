<template>
  <div class="animated fadeIn">

    <b-row>
      <b-col lg="12">
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :isLoading.sync="isLoading"
          :pagination-options="{
            enabled: true,
          }"
          :rows="rows"
          :columns="columns"
        />
      </b-col>

    </b-row><!--/.row-->

    <br>

  </div>

</template>

<script>
import { shuffleArray } from '@/shared/utils'
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default {
  name: 'grid-tables',
  components: {
    VueGoodTable,
  },
  data(){
    return {
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: 'Search for name', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            trigger: 'enter', //only trigger on enter not on keyup
          },
        },
        {
          label: 'Age',
          field: 'age',
          type: 'number',
        },
        // {
        //   label: 'Created On',
        //   field: 'createdAt',
        //   type: 'date',
        //   dateInputFormat: 'yyyy-MM-dd',
        //   dateOutputFormat: 'MMM Do yy',
        // },
        {
          label: 'Percent',
          field: 'score',
          type: 'percentage',
        },
      ],
      rows: [
        { id:1, name:"John", age: 20, createdAt: '2011-10-31',score: 0.03343 },
        { id:2, name:"Jane", age: 24, createdAt: '2011-10-31', score: 0.03343 },
        { id:3, name:"Susan", age: 16, createdAt: '2011-10-30', score: 0.03343 },
        { id:4, name:"Chris", age: 55, createdAt: '2011-10-11', score: 0.03343 },
        { id:5, name:"Dan", age: 40, createdAt: '2011-10-21', score: 0.03343 },
        { id:6, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
        { id:7, name:"John", age: 20, createdAt: '2011-10-31',score: 0.03343 },
        { id:8, name:"Jane", age: 24, createdAt: '2011-10-31', score: 0.03343 },
        { id:9, name:"Susan", age: 16, createdAt: '2011-10-30', score: 0.03343 },
        { id:10, name:"Chris", age: 55, createdAt: '2011-10-11', score: 0.03343 },
        { id:11, name:"Dan", age: 40, createdAt: '2011-10-21', score: 0.03343 },
        { id:12, name:"John", age: 20, createdAt: '2011-10-31', score: 0.03343 },
      ],
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: {
          field: '',
          type: '',
        },
        page: 1,
        perPage: 10
      }
    };
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
      // console.log('loadItems', this.serverParams);
      /*
      getFromServer(this.serverParams).then(response => {
        this.totalRecords = response.totalRecords;
        this.rows = response.rows;
      });
      */
    }
  }
}
</script>
